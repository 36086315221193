import React, { useContext, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Link, navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { setRedirectUrlSessionStorage } from "@/account/redirect";
import { SessionType } from "@/autoGeneratedGlobalTypes";
import GlobalContext from "@/contexts/Global/GlobalContext";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import useCreateSession from "@/hooks/useCreateSession";
import Loader from "@components/common/loader";
import { UserAvatar } from "@components/common/userAvatar";
import { UserAvatarTypeEnum } from "@components/common/userAvatar/types";
import { FormTypeEnum } from "@components/layout/modals/types";
import { setAuthParamToURL } from "@components/layout/modals/utils";

import { Icon, IconSizeEnum, IconTypeEnum } from "../common/icon";
import { CATALOG_PAGE_NAME, MIN_PAYMENT_AMOUNT } from "../constants";

import AppointmentContext from "./context/AppointmentContext";
import { AppointmentContextType } from "./context/types";
import {
  getAppointmentPageData as getAppointmentPageDataType,
  getAppointmentPageDataVariables,
} from "./graphql/__generated__/getAppointmentPageData";
import { GET_APPOINTMENT_PAGE_DATA } from "./graphql/GET_APPOINTMENT_PAGE_DATA";
import PaymentForm from "./paymentForm";
import PaymentResult from "./paymentResult";
import PhoneSession from "./phoneSession";
import SessionProcessBar from "./sessionProcessBar";
import { AppointmentProps, AppointmentStatusEnum } from "./types";
import {
  areMoneyAndFreeMinutesEnoughForCall,
  getInitialAppointmentStatus,
  isSessionReadyToBeCreated,
} from "./utils";
import "./styles.scss";

const Appointment = ({
  expertId, sessionType, isSuccess, isFailure, redirectToPaymentFlag, isTrialAllowed,
}: AppointmentProps) => {
  const {
    appointmentStatus,
    setAppointmentStatus,
  } = useContext<AppointmentContextType>(AppointmentContext);
  const {
    balance, setBalance, freeMinutesCount, setFreeMinutesCount, setPhone, unfinishedPaymentId,
  } = useContext<UserContextType>(UserContext);
  const { setIsAlertShown } = useContext(GlobalContext);

  const [createSession] = useCreateSession({
    sessionType,
    failCallback: () => {
      setAppointmentStatus(AppointmentStatusEnum.Payment);
    },
  });

  const { data, loading, error } = useQuery<
    getAppointmentPageDataType,
    getAppointmentPageDataVariables
  >(GET_APPOINTMENT_PAGE_DATA, {
    variables: { id: expertId },
    fetchPolicy: "no-cache",
  });

  // Turning off the alert
  useEffect(() => {
    setIsAlertShown(false);

    return () => {
      setIsAlertShown(true);
    };
  }, [setIsAlertShown]);

  useEffect(() => {
    if (!expertId) {
      navigate("/");
    }
  }, [expertId]);

  useEffect(() => {
    if (appointmentStatus) {
      return;
    }

    if (data && !loading && !error) {
      setBalance(data.getMyProfile.balance);
      setFreeMinutesCount(data.getMyProfile.user.freeMinutesLeft);
      setPhone(data.getMyProfile.phone || "");
      let rate = data.getExpert.rates.videoCall;
      switch (sessionType) {
        case SessionType.PHONE_CALL: rate = data.getExpert.rates.phoneCall; break;
        case SessionType.CHAT: rate = data.getExpert.rates.chat; break;
        default: rate = data.getExpert.rates.videoCall; break;
      }
      const initAppointmentState = getInitialAppointmentStatus(
        sessionType,
        data.getMyProfile.balance?.amount ?? null,
        rate,
        data.getMyProfile.user.freeMinutesLeft ?? 0,
        isTrialAllowed,
        isSuccess,
        isFailure,
      );

      if (sessionType === SessionType.PHONE_CALL && !data.getMyProfile.phone) {
        setRedirectUrlSessionStorage(window.location.pathname);
        setAuthParamToURL(location, FormTypeEnum.PhoneQuestionnaire);
      }

      if (!redirectToPaymentFlag) {
        setAppointmentStatus(initAppointmentState);
      }

      if ((initAppointmentState === AppointmentStatusEnum.Payment
        || initAppointmentState === AppointmentStatusEnum.StartOrPayChoice) && redirectToPaymentFlag) {
        setAppointmentStatus(initAppointmentState);
      }

      if ((initAppointmentState === AppointmentStatusEnum.PaymentSkipped
        || initAppointmentState === AppointmentStatusEnum.SessionStart)
        && redirectToPaymentFlag) {
        setAppointmentStatus(AppointmentStatusEnum.StartOrPayChoice);
      }
    }
  }, [appointmentStatus, data, loading, error, sessionType, isSuccess, isFailure,
    setBalance, setPhone, setFreeMinutesCount, setAppointmentStatus,
    redirectToPaymentFlag, isTrialAllowed]);

  useEffect(() => {
    if (sessionType !== SessionType.PHONE_CALL
      && isSessionReadyToBeCreated(appointmentStatus)
      && unfinishedPaymentId === null) {
      createSession(expertId);
    }
  }, [appointmentStatus, createSession, expertId, sessionType, unfinishedPaymentId]);

  if (loading || unfinishedPaymentId !== null) {
    return <Loader />;
  }

  // todo: maybe make something with error

  if (!data) {
    return null;
  }

  let callRate: number;
  switch (sessionType) {
    case SessionType.PHONE_CALL: callRate = data.getExpert.rates.phoneCall; break;
    case SessionType.CHAT: callRate = data.getExpert.rates.chat; break;
    default: callRate = data.getExpert.rates.videoCall; break;
  }

  const handlePaymentResultButtonClick = () => {
    if (appointmentStatus === AppointmentStatusEnum.PaymentSuccess) {
      setAppointmentStatus(AppointmentStatusEnum.SessionStart);
    }
    if (appointmentStatus === AppointmentStatusEnum.PaymentFailed) {
      setAppointmentStatus(
        areMoneyAndFreeMinutesEnoughForCall(
          balance?.amount ?? null,
          callRate,
          freeMinutesCount ?? 0,
          sessionType,
          isTrialAllowed,
        )
          ? AppointmentStatusEnum.StartOrPayChoice
          : AppointmentStatusEnum.Payment,
      );
    }
  };

  return (
    <div className="appointment">
      <Helmet>
        <body className="footer--short header--short" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="appointment__expert-block">
        <Link to={`/${CATALOG_PAGE_NAME}`}>
          <Icon
            className="appointment__close-button"
            type={IconTypeEnum.Close}
            size={IconSizeEnum.Size32}
          />
        </Link>
        <div className="appointment__expert">
          <div className="appointment__awaiting-text">Вас ожидает...</div>
          <UserAvatar
            mobileImage={data.getExpert.mobileImage}
            desktopImage={data.getExpert.desktopImage}
            name={data.getExpert.name ?? ""}
            // userId={expertId}
            type={UserAvatarTypeEnum.Appointment}
          />
          <h3 className="appointment__name">{data.getExpert.name}</h3>
          <div className="appointment__rate">
            {callRate}
            {" "}
            ₽/мин
          </div>
          {sessionType !== SessionType.PHONE_CALL && isTrialAllowed
            && !!data.getMyProfile.user.freeMinutesLeft && (
              <>
                <div className="appointment__gift">
                  <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size20} />
                  {`${data.getMyProfile.user.freeMinutesLeft}\u00a0мин ${isTrialAllowed ? "бесплатно" : "в\u00a0подарок"}`}
                </div>
                {!isTrialAllowed && (
                  <div className="appointment__gift-hint">
                    {`при первом пополнении от ${MIN_PAYMENT_AMOUNT}\u00a0₽`}
                  </div>
                )}
              </>
          )}
        </div>
      </div>
      <div className="appointment__process">
        <SessionProcessBar sessionType={sessionType} status={appointmentStatus} />
        {sessionType === SessionType.PHONE_CALL
          && isSessionReadyToBeCreated(appointmentStatus)
          && (
            <PhoneSession expertId={expertId} appointmentStatus={appointmentStatus} />
          )}

        {appointmentStatus
          && [AppointmentStatusEnum.PaymentFailed, AppointmentStatusEnum.PaymentSuccess].includes(
            appointmentStatus,
          ) && <PaymentResult handleButtonClick={handlePaymentResultButtonClick} />}

        {appointmentStatus
          && [AppointmentStatusEnum.StartOrPayChoice, AppointmentStatusEnum.Payment].includes(
            appointmentStatus,
          ) && (
          <PaymentForm
            sessionType={sessionType}
            userCards={data.getUserCards}
            balance={data.getMyProfile.balance}
            freeMinutesCount={
              sessionType !== SessionType.PHONE_CALL
                ? data.getMyProfile.user.freeMinutesLeft
                : 0
            }
            callRate={callRate}
            expertId={expertId}
            isTrialAllowed={isTrialAllowed}
          />
        )}
      </div>
    </div>
  );
};

export default Appointment;
